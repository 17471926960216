export const MaintenanceActions = {
  LoadSettings: class {
    static {
      this.type = '[Maintenance] LoadSettings';
    }
    constructor() {}
  },
  UpdateSettings: class {
    static {
      this.type = '[Maintenance] UpdateSettings';
    }
    constructor(settings) {
      this.settings = settings;
    }
  }
};