import { VIRTUAL_PROPS_ACCESSOR, VIRTUAL_PROPS_DB_COL_NAME } from './constants';
export class VirtualPropertyHelper {
  static has(model, property) {
    return typeof model[VIRTUAL_PROPS_ACCESSOR]?.[property] !== 'undefined';
  }
  static get(model, property, defaultValue = null) {
    return this.has(model, property) ? model[VIRTUAL_PROPS_ACCESSOR][property] : defaultValue;
  }
  static getAll(model) {
    return model[VIRTUAL_PROPS_ACCESSOR] || {};
  }
  static set(model, property, value) {
    if (!model[VIRTUAL_PROPS_ACCESSOR]) throw new Error('Model has no virtualProperties');
    model[VIRTUAL_PROPS_ACCESSOR][property] = value;
  }
  static accessor(property) {
    if (property) return VIRTUAL_PROPS_ACCESSOR + '.' + property;else return VIRTUAL_PROPS_ACCESSOR;
  }
  static dbColName() {
    return VIRTUAL_PROPS_DB_COL_NAME;
  }
}
export const VPropertyHelper = VirtualPropertyHelper;