import { isNumericString } from '../utils';
import { ClassTransformService } from '../model';
/**
 * If AppRequest uses http GET, properties need to be transferred as query params.
 * To make sure they are correctly hydrated again, they need additional transformation instructions.
 * If the parameter is passed as part of path, use @ItemPathParam instead.
 */
export function HttpGETRequestProperty(type) {
  return function ItemModelPropDecorator(target, propertyName) {
    // do NOT add default type meta info! It would already transform value type before executing custom transform handler!
    // Type()(target, propertyName);
    // add custom transformation handler for correct transformation from/to http param.
    ClassTransformService.getMetadataStorage().addTransformMetadata({
      target: target instanceof Function ? target : target.constructor,
      propertyName,
      transformFn: getTransformFnForType(type),
      options: {}
    });
  };
}
export function getTransformFnForType(propType) {
  if (propType === 'string') {
    return ({
      value
    }) => {
      return String(value);
    };
  } else if (propType === 'boolean') {
    // class-transformers native type conversion will only use Boolean(value).
    // this will not convert stringified values correctly, so we need a custom implementation.
    return ({
      value
    }) => {
      const booleanValue = interpreteAsBoolean(value);
      return booleanValue;
    };
  } else {
    // number
    return ({
      value
    }) => {
      return interpreteAsNumber(value);
    };
  }
}
export function interpreteAsBoolean(value) {
  if (typeof value === 'boolean') return value;
  if ([1, '1', 'true'].includes(value)) return true;
  if ([0, '0', 'false'].includes(value)) return false;
  return Boolean(value);
}
export function interpreteAsNumber(value) {
  if (typeof value === 'number') return value;
  if (typeof value !== 'string') throw new Error('Unexpected value type while converting HttpGETRequestProperty: type cannot be converted to number. ' + typeof value);
  if (!isNumericString(value)) throw new Error('Unexpected value while converting HttpGETRequestProperty: string is not a valid number. ' + value);
  return Number(value);
}