import { Component } from '@angular/core';
import { LocalState } from '@common/localstate/frontend-shared';
import { PageState } from '@common/core/frontend-shared';
import { UserItem } from '@common/users/shared';
import { AccessState } from '@core/frontend-shared';
import { FeatureMenuItem, FeatureMenuItemService, FeatureService } from '@core/frontend-shared/feature';
import { Select } from '@ngxs/store';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { Observable } from 'rxjs';
import { fadeAnimation } from './animations';
import { userTopMenu } from './menus';
import { UiIconLibrary } from '@common/ui/frontend-shared/icons';

@Component({
	selector: 'pflegetheke-admin-app',
	templateUrl: './app.component.html',
	styles: [
		`:host	{ display:block; height:100%; }
		.layout-content, user-login { display:block; height:100%; }
		@media(max-width:639px) {
			// .page-title {display:none}
		}
		`
	],
	animations:[fadeAnimation],
})
@AutoUnsubscribe()
export class AppComponent {
	
	@Select(AccessState.isAdmin) isAdmin$: Observable<boolean>;
	@Select(LocalState.get('pinAdminMenu')) pinAdminMenu$: Observable<boolean>;
	@Select(AccessState.loggedIn) loggedIn$: Observable<boolean>;
	@Select(AccessState.accessData('user')) userData$:Observable<UserItem>;
	@Select(PageState.title) pageTitle$: Observable<string>;
	@Select(AccessState.isReady) accessIsReady$: Observable<boolean>;

	userTopMenu = userTopMenu;
	menuModel:FeatureMenuItem[];

	constructor(
		icons:UiIconLibrary,
		featureService:FeatureService,
		featureMenuItemService:FeatureMenuItemService,
	) { 
		icons.addIcons(...featureService.getRequiredIcons())

		featureMenuItemService.getAccessibleMenuItems('adminToolbar')
			.pipe(takeWhileAlive(this))
			.subscribe(menuItems=>{
				this.menuModel = [];
				menuItems.forEach((menuItem:FeatureMenuItem)=>{
					this.menuModel.push(menuItem)
				});
			})

		// title route icon
	}


}
